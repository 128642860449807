const importingLicenseConsentingEntityData = [
    {
        label: "ANCINE",
        value: "ANCINE",
    },
    {
        label: "ANEEL",
        value: "ANEEL",
    },
    {
        label: "ANP",
        value: "ANP",
    },
    {
        label: "CNEN",
        value: "CNEN",
    },
    {
        label: "CNPQ",
        value: "CNPQ",
    },
    {
        label: "COMEXE",
        value: "COMEXE",
    },
    {
        label: "DECEX",
        value: "DECEX",
    },
    {
        label: "DNPM",
        value: "DNPM",
    },
    {
        label: "DPF",
        value: "DPF",
    },
    {
        label: "EBC",
        value: "EBC",
    },
    {
        label: "IBAMA",
        value: "IBAMA",
    },
    {
        label: "INMETRO",
        value: "INMETRO",
    },
    {
        label: "MAPA",
        value: "MAPA",
    },
    {
        label: "MCTIC",
        value: "MCTIC",
    },
    {
        label: "MD",
        value: "MD",
    },
    {
        label: "SUFRAMA",
        value: "SUFRAMA",
    },
    {
        label: "NÃO SE APLICA",
        value: "NÃO_SE_APLICA",
    },
];

export default importingLicenseConsentingEntityData;
